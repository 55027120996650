import { organizationActions } from "./organization";

export { default as organizationSlice } from "./organization";

export const organization_role_actions = {
	...organizationActions,
};



